import ArtCard from "./ArtCard";
import TopNavBar from "./TopNavbar";
import React from 'react';
var listOfImages = [];
class Portfolio extends React.Component{
    importAll(r) {
        return r.keys().map(r);
    }
    componentWillMount() {
        listOfImages = this.importAll(require.context('../public/images/', false, /\.(png|jpe?g|svg)$/));
    }
    render(){
        return(
          <div>
            <TopNavBar />
            <div className="container portfolio">
            {
                listOfImages.reverse().map((image, index) =>
                    <ArtCard piece={{image: image, description: descriptions[index]}} key={index}/>
                )
            }
            </div>
          </div>
        )
    }
}

export default Portfolio;

const descriptions = [
    "digital drawing of Urouge from One Piece",
    "digital drawing of Nacht Faust from Black Clover",
    "digital drawing of Junko Enoshima from Danganronpa",
    "digital drawing of Shanks from One Piece",
    "digital drawing of Gon and Killua from Hunter X Hunter",
    "digital drawing of Who's Who from One Piece",
    "digital drawing of Kirby from Kirby",
    "digital drawing of Boruto from Boruto",
    "digital drawing of Megumi, Itadori, Kugisaki, and Gojo from Jujutsu Kaisen",
    "digital drawing of Denji and Makima from Chainsaw Man",
    "digital drawing of Perona from One Piece",
    "digital drawing of Luffy from One Piece",
    "pencil drawing of Saitama from One Punch Man",
    "digital drawing of Jodio Joestar from Jojo's Bizarre Adventure",
    "marker drawing of Kizaru, Akainu, and Aokiji from One Piece",
    "digital drawing of Beam from Chainsaw Man",
    "digital drawing of Narciso Anasui and Diver Down from Jojo's Bizarre Adventure",
    "pen drawing of Kaido from One Piece",
    "colored pencil drawing of Morel and his students from Hunter X Hunter",
    "marker drawing of dragon form Kaido from One Piece",
    "digital drawing of Luffy from One Piece",
    "digital drawing of Rohan kishibe from Jojo's Bizarre Adventure",
    "pen drawing of The Hand from Jojo's Bizarre Adventure",
    "digital drawing of Jotaro from Jojo's Bizarre Adventure",
    "pen drawing of Jotaro from Jojo's Bizarre Adventure",
    "pen drawing of Luffy from One Piece",
    "colored pencil drawing of Rengoku and Akaza from Demon Slayer",
    "colored pencil drawing of Rengoku from Demon Slayer",
    "colored pencil drawing of Sasuke from Naruto",
    "colored pencil drawing of Madara from Naruto",
    "pen drawing of Tanjiro from Demon Slayer"
]
