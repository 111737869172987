import TopNavBar from './TopNavbar';
import React from "react";

function Home(){
    return(
        <>
            <TopNavBar />
            <div className='container home' style={{justifyContent:'left'}}>
                <img src={process.env.PUBLIC_URL + '/images/027.jpg'} alt='digital drawing of Narciso Anasui and Diver Down from Jojos Bizarre Adventure'/>
                <p>
                    Commission custom digital art pieces at affordable prices!
                </p>
            </div>
            <div className='container home' style={{justifyContent:'right'}}>
                <p id='b'>
                    Buy prints for as low as $20!
                </p>
                <img src={process.env.PUBLIC_URL + '/images/026.jpg'} alt='digital drawing of Whos Who from One Piece' id='a'/>
            </div>
            <div className='container home' style={{justifyContent:'left', paddingBottom:"20px"}}>
                <img src={process.env.PUBLIC_URL + '/images/015.jpg'} alt='digital drawing of Luffy from One Piece'/>
                <p>
                    Or just enjoy my art, any support helps!
                </p>
            </div>
        </>
    );
}

export default Home;