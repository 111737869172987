import React from "react";

const ArtCard = ({piece}) => {
    return(
        <div className="artCard" /*onClick={e => enhanceImage(e.target)}*/>
            <img src={piece.image} alt={piece.description}/>
            <h2>{piece.description}</h2>
        </div>
    );
}

/*function enhanceImage(e){
    if(!fullscreen){
        e.className = "artCard enhanced";
        document.body.style.overflow = "hidden";
    }
    else{
        e.className = "artCard";
        document.body.style.overflow = "scroll";
    }
    fullscreen = !fullscreen;
}*/

export default ArtCard;