import TopNavBar from "./TopNavbar";
import React from "react";
import { Link } from "react-router-dom";

function Links() {
    return(
        <>
            <TopNavBar />
            <div className="container linkpage">
                <ul className="links">
                    <Link className="link" to={"https://www.instagram.com/_dalton.notlad_/"} target={"_blank"} rel={"noopener noreferrer"}>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" alt="instagram logo"/>
                        <h2>Instagram</h2>
                    </Link>
                    <Link className="link" to={"https://www.tiktok.com/@daltonsart_"} target={"_blank"} rel={"noopener noreferrer"}>
                        <img src="https://d12jofbmgge65s.cloudfront.net/wp-content/uploads/2023/01/tech-guide_header-image-tiktok.webp" alt="tik tok logo"/>
                        <h2>Tik Tok</h2>
                    </Link>
                    <Link className="link" to={"https://www.etsy.com/shop/artbydaltondesigns/?etsrc=sdt"} target={"_blank"} rel={"noopener noreferrer"}>
                        <img src="https://cdn-icons-png.flaticon.com/512/3536/3536995.png" alt="etsy logo"/>
                        <h2>Etsy</h2>
                    </Link>
                </ul>
            </div>
        </>
    );
}
export default Links;