import { Link } from "react-router-dom";
import React from "react";
import {useState, useEffect} from "react";

function TopNavBar() {
  const scrollDirection = useScrollDirection();

  return (
    <div className={`navbar ${ scrollDirection === "down" ? "hide" : "show"}`}>
        <h1>Dalton's Art</h1>
        <ul>
            <li><Link to="/" className="link">Home</Link></li>
            <li><Link to="/portfolio" className="link">Portfolio</Link></li>
            <li><Link to="/commission" className="link">Commission</Link></li>
            <li><Link to="/links" className="link">Links</Link></li>
            <li><Link to="/shop" className="link">Shop</Link></li>
        </ul>
    </div>
  );
}

export default TopNavBar;

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    }
  }, [scrollDirection]);

  return scrollDirection;
};