import TopNavBar from "./TopNavbar";
import React from "react";

function Shop() {
    return(
        <>
            <TopNavBar />
            <div className="container shop">
                <h1>Shop coming soon!</h1>
            </div>
        </>
    );
}
export default Shop;