import './App.css';
import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Portfolio from "./Portfolio";
import Commission from "./Commission";
import Links from "./Links";
import Shop from "./Shop";
import Home from "./Home";

const App = () => {

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/portfolio' element={<Portfolio />}/>
        <Route path='/commission' element={<Commission />}/>
        <Route path='/links' element={<Links />}/>
        <Route path='/shop' element={<Shop />}/>
      </Routes>
    </div> 
  );
}

export default App;
