import TopNavBar from "./TopNavbar";
import React from "react";
import { Link } from "react-router-dom";

const Commission = () => {
    return(
        <>
            <TopNavBar />
            <div id="commission">
                <div className="container commission">
                    <Link className="email-button" to="https://form.jotform.com/232284698304159" target={"_blank"} rel={"noopener noreferrer"}><img src="https://ps.w.org/embed-form/assets/icon-256x256.png?rev=2612516" alt="Jotform logo"/><h2>Fill Out Custom Commission Form</h2></Link>
                    <Link to="mailto:daltonsart.email@gmail.com?subject=Art Commission&body=Title: %0D%0A%0D%0ADescription: %0D%0A%0D%0AFrom: %0D%0A%0D%0A(Feel free to attach any necessary reference images)" className="email-button"><img src="https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/112-gmail_email_mail-512.png" alt="email icon"/><h2>Send me an email</h2></Link>
                    <Link className="email-button" to={"https://www.instagram.com/_dalton.notlad_/"} target={"_blank"} rel={"noopener noreferrer"}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" alt="Instagram logo"/><h2>Contact me on Instagram</h2></Link>
                </div>
                
            </div>
            
        </>
    );
}
export default Commission;